* {float: left;width: 100%;box-sizing: border-box;}
body {float: left;width: 100%;box-sizing: border-box;overflow: hidden;position: relative;font-family: "Inter Regular", "TwemojiMozilla-Regular";letter-spacing: 0.3px;}
.wrapper {float: left;width: 100%;overflow: hidden;}

/* common css */
.container {float: none;display: table;max-width: 1200px;}
/* common css */

/* payment header css */
.tabsHeaders {float: left;width: 100%;}
.tabsHeaders ul {margin: 14px 0px 10px;display: flex;vertical-align: middle;align-items: center;background-color: #A1B8D11D;padding: 13px 17px;border-radius: 7px;}
.tabsHeaders ul li {float: left;width: 33.33%;flex-basis: 33.33%;list-style: none;}
.tabsHeaders ul li div {display: flex;align-items: center;font-size: 14px;text-decoration: none;}
.tabsHeaders ul li div span   {float: left;width: 27px;background-color:#A1B8D1;height: 27px;color: #fff;display: flex;justify-content: center;align-items: center;border-radius: 6px;margin: 0 10px 0px 0;}
.tabsHeaders ul li div.active {color: #1D1D1D !important;}
.tabsHeaders ul li div.active span {background-color: #014087 !important;}
/* payment header css */

/* customer details page css */
.paymentformswrapps {float: left;width: 100%;position: relative;}
.paymentwrapper {float: left;width: 100%;padding: 35px 40px 5px;height: 100vh;box-sizing: border-box;position: relative;overflow: auto;}
.paymentHeader {float: left;width: 100%;position: relative;display: flex;align-items: center;}
.paymentwrapper .logowraps {float: left;width: 58px;height: 53px;background-color: #F2F2F2;border-radius: 10px;text-align: center;display: flex;align-items: center;justify-content: center;}
.paymentwrapper .logowraps img {float: left;width: 44px;}
.paymentHeader h4 {float: left;width: auto;margin: 0 0 0 12px;font-size: 19px;font-family: 'Inter Semi Bold';}
.paymentWrappers {float: left;width: 100%;position: relative;}
.paymentheadermain {float: left;width: 100%;position: relative;margin: 6px 0 13px;}
.paymentheadermain h4 {font-size: 17px;margin: 0px;float: left;width: auto;font-family: 'Inter Semi Bold';}
.paymentforms {float: left;width: 100%;position: relative;}
.rowcustom {float: left;width: 100%;margin-bottom: 15px;}
.rowcustom-col-2 {display: flex;justify-content: space-between;}
.rowcustom.rowcustom-col-2 .form-group {flex-basis: 49%;}
.form-group {float: left;width: 100%;position: relative;}
.form-group label {float: left;width: 100%;font-size: 13px;color: #9D9DA5;margin: 0px 0px 4px;position: relative;}
.form-group input {height: 48px;border: 1px solid #E0E0E5;border-radius: 7px;font-size: 13px;box-shadow: none !important;padding: 8px 17px;}
.form-group input::placeholder {color: #969696;}
.paymentTextfiled {float: left;width: 100%;position: relative;}
.paymentTextfiled .dollartextfiled {float: left;width: 30px;height: 30px;font-size: 13px;background-color: #E0E6FF;position: absolute;justify-content: center;align-items: center;border-radius: 6px;display: flex;left: 10px;top: 9px;}
.paymentTextfiled .form-control {padding: 8px 17px 8px 46px;}
.form-group select.form-control {height: 48px;border: 1px solid #E0E0E5;border-radius: 7px;font-size: 13px;box-shadow: none !important;padding: 8px 17px;background-image: url("../img/selectarrow.svg");background-position: 98% center;background-repeat: no-repeat;background-size: 17px;}
.reviewpaymentforms {float: left;width: 100%;padding: 20px 20px;border: 1px solid #E0E0E5;border-radius: 8px;box-sizing: border-box;position: relative;margin-bottom: 20px;}
.paymentinvoics {float: left;width: 100%;margin: 0 0 15px;padding: 0px 0px 10px;border-bottom: 1px solid #E0E0E5;}
.paymentinvoics li {
float: left;
font-size: 14px;
width: 100%;
display: flex;
font-family: 'Inter Medium';
list-style: none;
justify-content: center;
align-items: center;
margin: 0 0 10px;
color: #E25E24;
}
.paymentinvoics li label {
color: #1D1D1D;
}
.datwisepaymentmthds {
float: left;
width: 100%;
position: relative;
margin-bottom: 19px;
}
.datwisepaymentmthds span {
float: left;
width: 100%;
color: #9D9DA5;
font-size: 14px;
margin: 0px 0px 5px;
}
.datwisepaymentmthds h5 {
float: left;
width: 100%;
margin: 0px;
font-family: 'Inter Medium';
font-size: 16px;
display: flex;
justify-content: flex-start;
align-items: center;
}
.datwisepaymentmthds h5 .mastercardsIcon {
width: 33px;
margin-right: 6px;
}
.alertmessage {
background-color: #F4EFEF;
border-color: #F4EFEF;
font-size: 13px;
color: #969191;
padding: 15px 21px;
}
/* customer details page css */

/* success payment page css  */
.successpymentwrapper {
float: none;
width: 475px;
background: #2C55FB 0% 0% no-repeat padding-box;
color: #fff;
border-radius: 14px;
position: relative;
text-align: center;
padding: 50px 0 0;
margin: 35px auto 15px;
display: table;
background-image: url("../img/successbackground.png");
background-position: right top;
background-size: auto;
background-repeat: no-repeat;
}
.successpymentwrapper h4{
float: left;
width: 100%;
font-size: 22px;
font-family: 'Inter Medium';
margin: 21px 0 8px;
position: relative;
}
.successpymentwrapper p{
float: none;
width: 52%;
font-family: 'Inter Light BETA';
color: #B2C1FF;
margin: 0px auto 16px;
display: table;
font-size: 16px;
}
.succesiocn {
float: left;
width: 105px;
height: 105px;
background-color: #fff;
border-radius: 100%;
padding: 10px;
position: absolute;
left: 0;
right: 0;
margin: 0 auto;
top: -53px;
z-index: 9;
}
.sucesspaymendeta {
float: left;
width: 100%;
display: flex;
background-color: #4A6DFA;
padding: 20px 25px;
font-size: 15px;
text-align: center;
justify-content: center;
align-items: center;
}
.sucesspaymendeta .succpmnt {
color: #B2C1FF;
font-size: 15px;
width: 37%;
text-align: left;
padding: 0px 10px 0px;
}
.sucesspaymendeta .succpmnt span {
color: #fff;
font-size: 17px;
margin: 0 0 2px;
}
.totalamount {
float: left;
width: 100%;
padding: 22px 10px 13px;
position: relative;
color: #B2C1FF;
font-size: 15px;
}
.totalamount h3 {
color: #fff;
font-family: 'Inter Medium';
margin: 0 0 2px;
font-size: 32px;
}
.successpymentbutns {
float: left;
width: 100%;
display: flex;
padding: 18px 17px;
position: relative;
column-count: 2;
column-gap: 19px;
}
.successpymentbutns .btn {
padding: 22px 19px;
font-size: 13px;
border-radius: 11px;
}
.successpymentbutns .btn.cancelbtn {
background-color: #1D1D1D;
color: #fff;
border-color: #1D1D1D;
}
.successpymentbutns .btn.savebtn  {
background-color: #FFFFFF;
color: #000000;
}
/* success payment page css  */

/* country code start here */
.selectNoInputField {
float: left;
width: 100%;
border: 1px solid #E0E0E5;
border-radius: 7px;
position: relative;
display: flex;
}
.selectNoInputField input {
height: 48px;
border: 0px;
padding: 10px 10px;
font-size: 13px;
outline: none;
width: 100%;
}
.selectNoInputField .inputBoxSelect {
width: 100px;
text-align: left;
margin: 0px;
position: relative;
padding-left: 16px;
}
.selectNoInputField .inputBoxSelect::after{
content: "";
float: left;
width: 1px;
height: 25px;
background-color: #c1c1c1;
position: absolute;
right: 0;
top: 12px;
}
.form-group.error .selectNoInputField {
    border-color: #F84646;
}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {
    border-color: #F84646 !important;
}
.countselet {
font-size: 12px;
float: left;
width: auto;
}
.flagsDropdown {
float: left;
width: 220px;
max-height: 200px;
overflow: auto;
margin: 0px;
padding: 0px;
position: absolute;
z-index: 9;
background-color: #fff;
box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
left: 0;
bottom: auto;
top: 45px;
}
.flagsDropdown li {
float: left;
width: 100%;
list-style: none;
display: flex;
padding: 10px 13px;
font-size: 12px;
cursor: pointer;
text-align: left;
vertical-align: middle;
align-items: center;
}
.flagsDropdown li:hover {
background-color: #eee;
}
.flagsDropdown li img {
width: 26px;
margin: 0px 8px 0px 0px;
}
.selectNoInputField .inputBoxSelect .selectEmojis {
padding: 1px;
border: 0px;
height: 100%;
width: 100%;
display: flex;
position: relative;
align-items: center;
text-align: center;
justify-content: flex-start;
background-color: transparent;
}
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
width: 20px;
height: 20px;
border-radius: 100%;
margin: 0px 7px 0px 0px;
object-fit: cover;
}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {
width: 7px;
margin: 0px 0px 0px 3px;
position: absolute;
right: 8px;
}
.btn.formcomnbtn {
background-color: #1D1D1D;
border-color: #1D1D1D;
width: auto;
padding: 14px 45px;
float: right;
font-size: 13px;
color: #cecece;
border-radius: 7px;
}
/* country code end here */

/* pyment invoice css start here */
.invoicpreviewcol {float: left;width: 100%;background-color: #fff;margin: 0;padding: 0px !important;z-index: 9999;border-radius: 20px;overflow: hidden;}
.invoicpreviewcolheader {float: left;width: 100%;background-color: #1D1D1D;color: #fff;padding: 15px 30px 21px;position: relative;}
.invoicpreviewcolheader .invoiceid {float: right;width: auto;text-align: left;position: relative;display: grid;}
.invoicpreviewcolheader .invoiceid span {color: #8B8B8B;font-size: 12px;margin: 0px 0 2px;display: table;}
.invoicpreviewcolheader .invoiceid div {font-size: 13px;}
.invoicpreviewcolheader h4 {float: left;width: 100%;color: #FFFFFF;letter-spacing: 0.3px;font-family: 'Inter Medium';margin: 9px 0 5px;font-size: 15px;}
.invoicpreviewcolheader p {float: left;color: #C1C1C1;width: 67%;font-size: 12px;margin: 0px 0 0px;}
.invoicpreviewpopupbody {float: left;width: 100%;padding: 26px 36px 36px 36px;background-color: rgba(220, 226, 232, 0.4);position: relative;}
.invoicpreviewpopupbody h5 {float: left;width: 100%;color: #8B8B8B;letter-spacing: 0.3px;font-size: 12px;}
.invoicpreviewpopupbody ul {float: left;width: 100%;padding: 0px;margin: 0px;position: relative;}
.invoicpreviewpopupbody ul li {float: left;width: 100%;list-style: none;padding: 6px 6px;background-color: #fff;border-radius: 7px;margin-bottom: 8px;}
.invoicpreviewpopupbody ul li .Descriphead {float: left;width: 100%;display: flex;justify-content: space-between;background-color: #F4EFEF;padding: 8px 14px;border-radius: 6px;}
.invoicpreviewpopupbody ul li .Descriphead h4 {float: left;width: auto;margin: 0px;color: #1D1D1D;font-family: 'Inter Semi Bold';letter-spacing: 0.3px;font-size: 13px;}
.invoicpreviewpopupbody ul li .Descriphead div {color: #E25E24;font-family: 'Inter Semi Bold';letter-spacing: 0.3px;font-size: 13px;width: auto;}
.invoicpreviewpopupbody ul li .discrcolswraps {float: left;width: 100%;display: flex;flex-wrap: nowrap;padding: 7px 14px;position: relative;}
.invoicpreviewpopupbody ul li .discrcolswraps .discrcols {float: left;width: 25%;text-align: left;color: #1D1D1D;display: grid;font-size: 15px;font-family: 'Inter Semi Bold';}
.invoicpreviewpopupbody ul li .discrcolswraps .discrcols span {float: left;width: 100%;color: #8B8B8B;font-size: 13px;margin: 0 0 4px;letter-spacing: 0.5px;font-family: 'Inter Regular';}
.totalinvoce {float: left;width: 100%;list-style: none;padding: 10px 20px;background-color: #fff;border-radius: 7px;display: grid;color: #fff;background-image: url("../img/totalammount.png");background-position: center;background-size: cover;background-repeat: no-repeat;}
.totalinvoce h4{float: left;width: 100%;color: #91A7FF;margin: 5px 0px 4px;position: relative;font-size: 13px;}
.totalinvoce h3{float: left;width: 100%;position: relative;font-family: 'Inter Semi Bold';font-size: 21px;}
.invoicpreviewcolheader img {position: absolute;left: 10px;top: 10px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(102%) contrast(102%);width: 18px;cursor: pointer;}
/* pyment invoice css end here */

/* payment footer section start here */
.footerpayment {float: left;width: 100%;padding: 40px 0px 0px 0px;border-top: 1px solid #E6E6E6;margin: 25px 0 0;position: relative;}
.paymentdetailfoot {float: left;width: 365px;position: relative;}
.paymentdetailfoot .paymentHeader .logowraps {width: 40px;height: 38px;}
.paymentdetailfoot .paymentHeader .logowraps img {width: 29px;}
.paymentdetailfoot .paymentHeader h4 {font-size: 16px;}
.paymentdetailfoot p {float: left;width: 100%;font-size: 14px;color: #676767;line-height: 23px;margin-bottom: 25px;}
.paymentdetailfoot .paymentHeader {margin-bottom: 14px;}
.paymentdetlink {float: left;width: 100%;position: relative;}
.paymentdetlink ul {float: left;width: 100%;padding: 0px;margin: 0px;display: flex;justify-content: left;align-items: center;}
.paymentdetlink ul li {list-style: none;font-size: 14px;float: left;width: auto;padding-right: 46px;}
.paymentdetlink ul li a {text-decoration: none;color: #1D1D1D;font-family: 'Inter Semi Bold';}
.paymentdetailfootcontact {float: right;width: auto;text-align: right;position: relative;margin: 37px 0 0;}
.paymentdetailfootcontact .contctcols {float: left;width: 100%;margin-bottom: 20px;display: grid;position: relative;}
.paymentdetailfootcontact .contctcols h5 {float: right;width: auto;font-size: 14px;color: #1D1D1D;font-family: 'Inter Semi Bold';}
.paymentdetailfootcontact .contctcols h2 {float: right;width: auto;font-size: 17px;font-family: 'Inter Semi Bold';}
.paymentdetailfootcontact .contctcols h2 a {font-family: 'Inter Semi Bold';color: #1D1D1D;text-decoration: none;}
.footercopyright {float: left;width: 100%;border-top: 1px solid #E6E6E6;margin: 15px 0 0;padding: 15px 0 15px;position: relative;display: flex;justify-content: space-between;align-items: center;vertical-align: middle;}
.footercopyright p {float: left;width: auto;font-size: 14px;color: #8D93A7;margin-bottom: 0px;}
.sociallinks {float: right;width: auto;padding: 0;margin: 0;display: flex;}
.sociallinks li {float: left;width: auto;list-style: none;margin: 0px 0px 0px 15px;}
.sociallinks li img {width: 40px;}
/* payment footer section end here */

/* input type number arrow hide */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
-moz-appearance: textfield;
}

/* form css start here */
.danger-color {font-size: 12px;color: #F84646;float: left;width: 100%;margin: 4px 0 0;padding-left: 18px;}
.form-group.error .form-control {border-color: #F84646;}
.form-group.error .form-groupfiled.expirydatswrapper input {border: 1px solid #F84646 !important;outline: none;}
.form-groupfiled {float: left;width: 100%;position: relative;}
.form-groupfiled .errorsuccessicon {float: left;width: 17px;position: absolute;right: 9px;top: 15px;}
/* form css end here */

/* expiry date set */
.expirydatswrapper input, .expirydatswrapper input.sc-dkrFOg.sc-hLBbgP.loNQrE.drVrxg, .expirydatswrapper input.sc-dkrFOg.sc-eDvSVe.loNQrE.dtbtLP {
    float: left;
    width: 100%;
}
.form-groupfiled.expirydatswrapper .react-datepicker__tab-loop .react-datepicker-popper {width: 225px;}

.expirydatswrapper .exp-wrapper {
    position: relative;
    border: 1px solid #E0E0E5;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    height: 48px;
    line-height: 36px;
    font-size: 24px;
    border-radius: 7px;
    padding: 0px 17px;
    cursor: text;
}
.expirydatswrapper .exp-wrapper:after {
    content: '/';
    position: absolute;
    left: 15%;
    margin-left: -4px;
    color: #aaa;
    top: 4px;
    font-size: 19px;
}
.expirydatswrapper input.exp {
    float: left;
    font-family: monospace;
    border: 0;
    width: 40px;
    outline: none;
    appearance: none;
    font-size: 14px;
    height: 100%;
    padding: 0px;
}